<template>
    <div class="content" :class="{'content--visible': isCircleVisible}">
        <div class="content__message" :class="{'content__message--visible': messageVisible}" @click="setCircleVisibility(true)">
            check this out
        </div>
        <div class="content__pages">
            <div class="content__page-container" :class="{'content__page-container--active': aboutMeSectionVisible, 'content__page-container--collapsed': familiarWithSectionsVisible || myMottoSectionVisible}">
                <div class="content__page-wrapper" :class="{'content__page-wrapper--visible': aboutMeSectionVisible}">
                    <div class="content__page content__page-content">
                        <window-close class="content__close" @click="aboutMeSectionVisible = false"/>
                        <div class="content__about-container">
                            <div class="content__about-header">about me:</div>
                            <div class="content__aboutme-section">
                                <div class="content__image-section">
                                    <img class="content__image" src="../assets/img/profile.jpeg">
                                </div>
                                <div class="content__info-section">
                                    Hi, my name is Krzysiek and I've been a proud graduate of ICT (Teleinformatics) @ AGH University of Science and Technology in Cracow since 2018.
                                    Been working as a professional programmer for even longer.
                                    I'm a fan of simplicity. Whether it's user interface or code itself. On every level. The simpler, the more minimalistic - the better.
                                </div>
                            </div>
                            <div class="content__socials-section">
                                <a href="https://hub.docker.com/u/krzysieg" target="_blank" class="link">
                                    <docker class="content__social-icon"/>
                                </a>
                                <a href="https://github.com/krzysieg" target="_blank" class="link">
                                    <github class="content__social-icon"/>
                                </a>
                                <a href="https://www.linkedin.com/in/krzysztof-gwara-45a998159/" target="_blank" class="link">
                                    <linkedin class="content__social-icon"/>
                                </a>
                                <a href="mailto:krzysztof@gwara.me" target="_blank" class="link">
                                    <email-outline class="content__social-icon"/>
                                </a>
                            </div>
                            <div class="content__about-header">interesting assets:</div>
                            <div class="content__assets-section">
                                <ul class="content__list">
                                    <li>
                                        <a href="/docs/Krzysztof_Gwara-Resume.pdf" target="_blank" class="link">CV / resume</a>
                                    </li>
                                    <li>
                                        <a href="/docs/Krzysztof_Gwara-Engineering_Thesis.pdf" target="_blank" class="link">engineering thesis</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content__page content__page-title" :class="{'content__page-title--visible': headersLoaded, 'content__page-title--expanded': familiarWithSectionsVisible}" @click="aboutMeSectionVisible = true">
                        <div class="content__page-header content__page-header-about" :class="{'content__page-header--visible': isCircleVisible}">
                            about<br>me
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__page-container" :class="{'content__page-container--active': familiarWithSectionsVisible, 'content__page-container--collapsed': aboutMeSectionVisible || myMottoSectionVisible}">
                <div class="content__page-wrapper" :class="{'content__page-wrapper--visible': familiarWithSectionsVisible}">
                    <div class="content__page content__page-content">
                        <window-close class="content__close" @click="familiarWithSectionsVisible = false"/>
                        <div class="content__technologies-container">
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/html.png">
                                <span class="content__tooltip">HTML</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/css.png">
                                <span class="content__tooltip">CSS</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/js.png">
                                <span class="content__tooltip">JavaScript</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon content__technology-icon--wide" src="../assets/img/jquery.png">
                                <span class="content__tooltip">JQuery</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/php.png">
                                <span class="content__tooltip">PHP</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/laravel.png">
                                <span class="content__tooltip">Laravel Framework</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/mysql.png">
                                <span class="content__tooltip">MySQL</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/psql.png">
                                <span class="content__tooltip">PostgreSQL</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/elastic.png">
                                <span class="content__tooltip">Elasticsearch</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon content__technology-icon--wide" src="../assets/img/apache.png">
                                <span class="content__tooltip">Apache HTTP Server</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/nginx.png">
                                <span class="content__tooltip">Nginx</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/linux.png">
                                <span class="content__tooltip">Linux</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/phpstorm.png">
                                <span class="content__tooltip">Php Storm IDE</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/docker.png">
                                <span class="content__tooltip">Docker</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/python.png">
                                <span class="content__tooltip">Python</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/jenkins.png">
                                <span class="content__tooltip">Jenkins</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon content__technology-icon--wide" src="../assets/img/node.png">
                                <span class="content__tooltip">Node JS</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/react.png">
                                <span class="content__tooltip">React</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/redux.png">
                                <span class="content__tooltip">Redux</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/vue.png">
                                <span class="content__tooltip">Vue.js</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/ts.png">
                                <span class="content__tooltip">Typescript</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/sass.png">
                                <span class="content__tooltip">Sass</span>
                            </div>
                            <div class="content__technology">
                                <img class="content__technology-icon" src="../assets/img/git.png">
                                <span class="content__tooltip">git</span>
                            </div>
                        </div>
                    </div>
                    <div class="content__page content__page-title" :class="{'content__page-title--visible': headersLoaded, 'content__page-title--expanded': familiarWithSectionsVisible}" @click="familiarWithSectionsVisible = true">
                        <div class="content__page-header content__page-header-familiar" :class="{'content__page-header--visible': isCircleVisible}">
                            familiar<br>with
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__page-container" :class="{'content__page-container--active': myMottoSectionVisible, 'content__page-container--collapsed': aboutMeSectionVisible || familiarWithSectionsVisible}">
                <div class="content__page-wrapper" :class="{'content__page-wrapper--visible': myMottoSectionVisible}">
                    <div class="content__page content__page-content">
                        <window-close class="content__close" @click="myMottoSectionVisible = false"/>
                        <img class="content__manifesto" alt="Holstee Manifesto" src="../assets/img/holstee.jpg">
                        <span class="content__tooltip content__tooltip-manifesto">Holstee Manifesto</span>
                    </div>
                    <div class="content__page content__page-title" :class="{'content__page-title--visible': headersLoaded, 'content__page-title--expanded': familiarWithSectionsVisible}" @click="myMottoSectionVisible = true">
                        <div class="content__page-header content__page-header-motto" :class="{'content__page-header--visible': isCircleVisible}">
                            agree<br>with
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Docker, EmailOutline, Github, Linkedin, WindowClose} from 'mdue';

const WIDTH_LIMIT = 1000;

export default {
    name: "Content",
    components: {WindowClose, Github, Linkedin, Docker, EmailOutline},
    data() {
        return {
            touchStartPosition: 0,
            headersLoaded: false,
            messageVisible: true,
            aboutMeSectionVisible: false,
            familiarWithSectionsVisible: false,
            myMottoSectionVisible: false,
            windowWidth: 0
        };
    },
    props: {
        isCircleVisible: Boolean,
        setCircleVisibility: Function
    },
    created() {
        document.addEventListener('wheel', this.handleScroll);
        document.addEventListener('touchstart', this.handleTouchStart);
        document.addEventListener('touchmove', this.handleTouchEnd);
        document.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('resize', this.handleResize);

        this.setWindowWidth();

        if (window.innerWidth < WIDTH_LIMIT) {
            this.hidAllSections = true;
        }
    },
    unmounted() {
        document.removeEventListener('wheel', this.handleScroll);
        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchmove', this.handleTouchEnd);
        document.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleScroll(e) {
            if (e.target.className === 'content__info-section') {
                return;
            }
            if (e.deltaY < 0 && this.isCircleVisible) {
                this.setCircleVisibility(false);
            } else if (e.deltaY > 0 && !this.isCircleVisible) {
                this.setCircleVisibility(true);
            }
        },
        handleTouchStart(e) {
            this.touchStartPosition = e.touches[0].clientY;
        },
        handleTouchEnd(e) {
            if (e.target.className === 'content__info-section') {
                return;
            }
            const touchEndPosition = e.changedTouches[0].clientY;
            if (this.touchStartPosition > touchEndPosition + 5 && !this.isCircleVisible) {
                this.setCircleVisibility(true);
            } else if (this.touchStartPosition < touchEndPosition - 5 && this.isCircleVisible) {
                this.setCircleVisibility(false);
            }
        },
        handleKeyUp(e) {
            if (e.code === 'Space' && !this.isCircleVisible) {
                this.setCircleVisibility(true);
            }
        },
        setWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        hideAllSections() {
            this.aboutMeSectionVisible = false;
            this.familiarWithSectionsVisible = false;
            this.myMottoSectionVisible = false;
        },
        handleResize() {
            const currentWindowWidth = window.innerWidth;
            if (currentWindowWidth < this.windowWidth && currentWindowWidth <= WIDTH_LIMIT && !this.hidAllSections) {
                this.hideAllSections();
                this.hidAllSections = true;
            } else if (currentWindowWidth > this.windowWidth && currentWindowWidth > WIDTH_LIMIT) {
                this.hidAllSections = false;
            }
            this.setWindowWidth();
        }
    },
    watch: {
        isCircleVisible(newValue, oldValue) {
            if (!oldValue && newValue) {
                setTimeout(() => {
                    this.headersLoaded = true;
                }, 2 * 1000);

                this.messageVisible = false;
            }

            if (oldValue && !newValue) {
                this.headersLoaded = false;
                this.hideAllSections();

                setTimeout(() => {
                    this.messageVisible = true;
                }, 1 * 1000);
            }
        }
    }
}
</script>