<template>
    <div class="loader" v-if="!(isVideoLoaded && twoSecondsPassed)">
        <div class="loader__content">
            <div class="loader__bubble loader__bubble--orange"></div>
            <div class="loader__bubble loader__bubble--salmon"></div>
            <div class="loader__bubble loader__bubble--yellow"></div>
            <div class="loader__bubble loader__bubble--red"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        data() {
            return {
                twoSecondsPassed: false
            }
        },
        props: {
            isVideoLoaded: Boolean
        },
        mounted() {
            setTimeout(() => {
                this.twoSecondsPassed = true;
            }, 2 * 1000);
        }
    }
</script>