<template>
    <div class="welcome" :class="{'welcome--hidden': isCircleVisible}">
        <div class="welcome__content">
            <div class="welcome__header">
                <div>
                    <div>Krzysztof</div>
                    <div>Gwara</div>
                </div>
            </div>
            <div class="welcome__socials">
                <div class="welcome__socials-header">
                    socials
                </div>
                <div class="welcome__socials-icons">
                    <a href="https://hub.docker.com/u/krzysieg" target="_blank" class="link"><docker class="welcome__socials-icon welcome__socials-icon-docker"/></a>
                    <a href="https://github.com/krzysieg" target="_blank" class="link"><github class="welcome__socials-icon welcome__socials-icon-github"/></a>
                    <a href="https://www.linkedin.com/in/krzysztof-gwara-45a998159/" target="_blank" class="link"><linkedin class="welcome__socials-icon welcome__socials-icon-linkedin"/></a>
                </div>
            </div>
            <div class="welcome__typed">
                <span id="welcome__typed"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import Typed from "typed.js";
    import {Github, Linkedin, Docker} from "mdue";

    export default {
        name: "Welcome",
        components: {Github, Linkedin, Docker},
        props: {
            isCircleVisible: Boolean
        },
        mounted() {
            const options = {
                strings: [
                    'full stack developer',
                    'webdeveloper',
                    'webdesigner',
                    'laravel fan',
                    'clean code believer',
                    'minimalism admirer',
                    'docker proponent'
                ],
                typeSpeed: 75,
                backSpeed: 55,
                loop: true
            };
            new Typed('#welcome__typed', options);
        }
    }
</script>