<template>
    <Loader :isVideoLoaded="isVideoLoaded"/>
    <Background :setVideoLoaded="setVideoLoaded"/>
    <Welcome :isCircleVisible="isCircleVisible"/>
    <Content :isCircleVisible="isCircleVisible" :setCircleVisibility="setCircleVisibility"/>
</template>

<script>
    import Loader from "./components/Loader";
    import Background from "./components/Background";
    import Welcome from "./components/Welcome";
    import Content from "./components/Content";

    export default {
        name: 'App',
        data() {
            return {
                isCircleVisible: false,
                isVideoLoaded: false
            }
        },
        methods: {
            setCircleVisibility(isVisible) {
                this.isCircleVisible = isVisible;
            },
            setVideoLoaded() {
                this.isVideoLoaded = true;
            }
        },
        components: {Loader, Background, Welcome, Content}
    }
</script>
