<template>
    <div class="background">
        <video class="background__video" ref="background" playsinline autoplay muted loop>
            <source src="../assets/video/coffee5.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
    export default {
        name: "Background",
        data() {
            return {
                background: null
            };
        },
        props: {
            setVideoLoaded: Function
        },
        mounted() {
            this.background = this.$refs.background;
            this.background.addEventListener('loadeddata', this.handleLoadedData)
        },
        created() {
            document.addEventListener('mousemove', this.moveBackground);
        },
        unmounted() {
            document.removeEventListener('mousemove', this.moveBackground);
        },
        methods: {
            handleLoadedData() {
                this.setVideoLoaded();
            },
            moveBackground(e) {
                const middleX = Math.floor(window.innerWidth / 2);
                const middleY = Math.floor(window.innerHeight / 2);
                const mouseX = e.clientX;
                const mouseY = e.clientY;
                const displacementX = Math.floor((mouseX - middleX) / middleX * 5);
                const displacementY = Math.floor((mouseY - middleY) / middleY * 5);

                this.background.style.left = `calc(50% - ${displacementX}px)`;
                this.background.style.top = `calc(50% - ${displacementY}px)`;
            }
        }
    }
</script>